exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-certificacion-jsx": () => import("./../../../src/pages/certificacion.jsx" /* webpackChunkName: "component---src-pages-certificacion-jsx" */),
  "component---src-pages-chile-chontal-jsx": () => import("./../../../src/pages/chile-chontal.jsx" /* webpackChunkName: "component---src-pages-chile-chontal-jsx" */),
  "component---src-pages-concursos-chocolate-mesa-agua-jsx": () => import("./../../../src/pages/concursos/chocolate-mesa-agua.jsx" /* webpackChunkName: "component---src-pages-concursos-chocolate-mesa-agua-jsx" */),
  "component---src-pages-concursos-concurso-cacao-mexicano-origen-jsx": () => import("./../../../src/pages/concursos/concurso-cacao-mexicano-origen.jsx" /* webpackChunkName: "component---src-pages-concursos-concurso-cacao-mexicano-origen-jsx" */),
  "component---src-pages-concursos-concurso-chocolate-mexicano-jsx": () => import("./../../../src/pages/concursos/concurso-chocolate-mexicano.jsx" /* webpackChunkName: "component---src-pages-concursos-concurso-chocolate-mexicano-jsx" */),
  "component---src-pages-concursos-encuentro-cocineras-tradicionales-jsx": () => import("./../../../src/pages/concursos/encuentro-cocineras-tradicionales.jsx" /* webpackChunkName: "component---src-pages-concursos-encuentro-cocineras-tradicionales-jsx" */),
  "component---src-pages-concursos-index-jsx": () => import("./../../../src/pages/concursos/index.jsx" /* webpackChunkName: "component---src-pages-concursos-index-jsx" */),
  "component---src-pages-estado-solicitud-jsx": () => import("./../../../src/pages/estado-solicitud.jsx" /* webpackChunkName: "component---src-pages-estado-solicitud-jsx" */),
  "component---src-pages-festival-del-chocolate-jsx": () => import("./../../../src/pages/festival-del-chocolate.jsx" /* webpackChunkName: "component---src-pages-festival-del-chocolate-jsx" */),
  "component---src-pages-ficha-pago-jsx": () => import("./../../../src/pages/ficha-pago.jsx" /* webpackChunkName: "component---src-pages-ficha-pago-jsx" */),
  "component---src-pages-historia-del-chocolate-jsx": () => import("./../../../src/pages/historia-del-chocolate.jsx" /* webpackChunkName: "component---src-pages-historia-del-chocolate-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kids-zone-jsx": () => import("./../../../src/pages/kids-zone.jsx" /* webpackChunkName: "component---src-pages-kids-zone-jsx" */),
  "component---src-pages-negocios-index-jsx": () => import("./../../../src/pages/negocios/index.jsx" /* webpackChunkName: "component---src-pages-negocios-index-jsx" */),
  "component---src-pages-negocios-login-jsx": () => import("./../../../src/pages/negocios/login.jsx" /* webpackChunkName: "component---src-pages-negocios-login-jsx" */),
  "component---src-pages-negocios-login-url-jsx": () => import("./../../../src/pages/negocios/loginUrl.jsx" /* webpackChunkName: "component---src-pages-negocios-login-url-jsx" */),
  "component---src-pages-negocios-registro-jsx": () => import("./../../../src/pages/negocios/registro.jsx" /* webpackChunkName: "component---src-pages-negocios-registro-jsx" */),
  "component---src-pages-negocios-success-jsx": () => import("./../../../src/pages/negocios/success.jsx" /* webpackChunkName: "component---src-pages-negocios-success-jsx" */),
  "component---src-pages-programa-jsx": () => import("./../../../src/pages/programa.jsx" /* webpackChunkName: "component---src-pages-programa-jsx" */),
  "component---src-pages-reservaciones-jsx": () => import("./../../../src/pages/reservaciones.jsx" /* webpackChunkName: "component---src-pages-reservaciones-jsx" */),
  "component---src-pages-solicitud-expositores-jsx": () => import("./../../../src/pages/solicitud-expositores.jsx" /* webpackChunkName: "component---src-pages-solicitud-expositores-jsx" */),
  "component---src-pages-ven-al-festival-jsx": () => import("./../../../src/pages/ven-al-festival.jsx" /* webpackChunkName: "component---src-pages-ven-al-festival-jsx" */)
}

